<template>
	<v-menu offset-y min-width="340" max-width="340" max-height="500" content-class="rounded-lg elevation-1">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" class="mx-2" icon v-bind="attrs" v-on="on">
				<v-icon color="primary">fal fa-user</v-icon>
			</v-btn>
		</template>

		<v-list>
			<v-list-item>
				<v-list-item-avatar height="80" width="80" class="kyano_avatar" color="blue">
					<v-img v-if="user().profilepicture" :src="`${config.url.cdn}/usercontent/avatar/${user.id}.png`"></v-img>
					<span v-else class="white--text text-h5">{{ firstnamechar() }}</span>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="title">{{ getname() }}</v-list-item-title>
					<v-list-item-subtitle>{{ user().email }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-divider></v-divider>

		<v-list>
			<template v-for="(item, index) in items">
				<v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

				<v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

				<v-list-item v-else-if="item.signout" :key="item.title" @click="signout">
					<v-list-item-avatar>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title v-html="item.title"></v-list-item-title>
						<v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
					</v-list-item-content>
					<v-icon>fal fa-chevron-right</v-icon>
				</v-list-item>
				<v-list-item v-else :key="item.title" :href="item.href">
					<v-list-item-avatar>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-html="item.title"></v-list-item-title>
						<v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
					</v-list-item-content>
					<v-icon>fal fa-chevron-right</v-icon>
				</v-list-item>
			</template>
		</v-list>
	</v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import * as cookies from 'js-cookie';
import config from '@/config';

export default {
	data: () => ({
		products: [
			{
				header: "Kyano Orders",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
			{
				title: "Snelwebcenter",
				icon: "fal fa-user",
			},
		],
		items: [
			// {
			// 	title: "Mijn Kyano account",
			// 	icon: "fal fa-user",
			// 	href: "https://kyanosite.nl",
			// },
			// { divider: true, inset: true },
			// {
			// 	title: "Instellingen",
			// 	icon: "fal fa-cog",
			// 	href: "/settings",
			// },
			// { divider: true, inset: true },
			// {
			// 	title: "Support",
			// 	icon: "fal fa-question-circle",
			// 	href: "https://kyanosite.nl",
			// },
			// { divider: true, inset: true },
			{
				signout: true,
				title: "Uitloggen",
				icon: "fal fa-sign-out",
			},
		],
	}),

	computed: {
		...mapGetters(['getshop', 'getuser']),
	},
	props: {
		breadcrumbs: Array,
	},



	methods: {
		shop() {
			return this.getshop;
		},
		getname() {
			return `${this.getuser.firstname} ${this.getuser.insertion !== null ? this.getuser.insertion : ""} ${this.getuser.lastname}`
		},
		user() {
			return this.getuser
		},
		firstnamechar() {
			return this.getuser.firstname.charAt(0).toUpperCase()
		},
		signout() {
			// TODO delete token
			cookies.remove('kyano_session');
			cookies.remove('kyano_user');
			location.replace(config.url.main);
		}
	},
};

</script>