<template>
  <v-menu
    offset-y
    min-width="340"
    max-width="340"
    max-height="500"
    content-class="rounded-lg elevation-1"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" dark v-bind="attrs" v-on="on" class="mr-4">
        <v-icon color="primary">mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-card width="440" class="kyano-apps-popup pa-3 text-center">
      <a href="https://kyano.app" class="pa-4">
        <v-img
          class="mx-auto"
          max-width="115"
          width="115"
          max-height="35"
          src="https://cdn.kyano.app/img/kyano-logo-dark.svg"
        ></v-img>
      </a>

      <v-row>
        <v-col cols="4" sm="4" v-for="item in items" :key="item.name" :data="item">
          <a class="kyano-app-box" :href="item.href">
            <v-img
              width="80"
              height="80"
              max-width="80"
              max-height="80"
              :src="item.icon"
            ></v-img>
            <v-list-item-title v-html="item.title"></v-list-item-title>
          </a>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Account",
        icon: "https://cdn.kyano.app/fav/kyano-account-icon.svg",
        href: "https://account.kyano.app",
      },
      {
        title: "Cards",
        icon: "https://cdn.kyano.app/fav/kyano-cards-icon.svg",
        href: "https://cards.kyano.app",
      },
      {
        title: "Orders",
        icon: "https://cdn.kyano.app/fav/kyano-icon.svg",
        href: "https://orders.kyano.app",
      },
      {  
        title: "Support",
        icon: "https://cdn.kyano.app/fav/kyano-support-icon.svg",
        href: "https://support.kyano.app",
      },          
    ],
  }),
};
</script>
<style scoped>
.kyano-apps-popup .kyano-app-box {
  background: transparent;
  display: block;
  border-radius: 12px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
}

.kyano-apps-popup .kyano-app-box:hover {
  background: #f5f5f9;
}

.kyano-apps-popup .kyano-app-box .v-image {
  border-radius: 15px;
  border: 1px solid #d1d1d6;
}

.kyano-apps-popup .kyano-app-box:hover .v-image {
  border: 1px solid transparent;
}
</style>